import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import ViewStudent from "views/pages/batch/ViewStudent";
import UpdateQuestion from "views/pages/entranceexam/UpdateQuestion";
import UpdateRegQuestion from "views/pages/regularexam/UpdateQuestion";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const CreateExam = Loadable(
  lazy(() => import("views/pages/entranceexam/CreateExam"))
);
const AllGrievanceRequest = Loadable(
  lazy(() => import("views/pages/AdminGrievance/AllGrievanceRequestAdmin"))
);

const CreateRegularExam = Loadable(
  lazy(() => import("views/pages/regularexam/CreateExam"))
);
const AllExams = Loadable(
  lazy(() => import("views/pages/entranceexam/AllExams"))
);
const AllRegularExams = Loadable(
  lazy(() => import("views/pages/regularexam/AllExams"))
);
const AllocateExam = Loadable(
  lazy(() => import("views/pages/regularexam/AllocateExam"))
);
const CreateBatch = Loadable(
  lazy(() => import("views/pages/batch/createBatch"))
);
const RaiseLeave = Loadable(
  lazy(() => import("views/pages/staffLeaveManagement/ManageLeave/raiseLeave"))
);
const ManageStaffLeave = Loadable(
  lazy(() =>
    import("views/pages/staffLeaveManagement/AdminLevelManageLeave/manageLeave")
  )
);
const UpdateReadingReportStatus = Loadable(
  lazy(() => import("views/pages/readingReport/UpdateReadingReportStatus"))
);

const AllBatches = Loadable(lazy(() => import("views/pages/batch/allBatches")));
const SetQuestionsForExam = Loadable(
  lazy(() => import("views/pages/entranceexam/SetQuestionsForExam"))
);
const SetQuestionsForRegularExam = Loadable(
  lazy(() => import("views/pages/regularexam/SetQuestionsForExam"))
);
const ShowQuestions = Loadable(
  lazy(() => import("views/pages/entranceexam/ShowQuestions"))
);
const ShowQuestionsForRegular = Loadable(
  lazy(() => import("views/pages/regularexam/ShowQuestions"))
);
const CreateQuestion = Loadable(
  lazy(() => import("views/pages/questionBank/CreateQuestion"))
);
const AllQuestions = Loadable(
  lazy(() => import("views/pages/questionBank/AllQuestions"))
);
const EditQuestion = Loadable(
  lazy(() => import("views/pages/questionBank/EditQuestion"))
);
const GenerateMerit = Loadable(
  lazy(() => import("views/pages/batch/GenerateMerit"))
);
const GenerateICard = Loadable(
  lazy(() => import("views/pages/batch/GenerateICard"))
);
const UploadResult = Loadable(
  lazy(() => import("views/pages/entranceexam/UploadResult"))
);
const UploadRegularResult = Loadable(
  lazy(() => import("views/pages/regularexam/UploadResult"))
);
const GenerateQuestionPaperRegular = Loadable(
  lazy(() => import("views/pages/regularexam/GenerateQuestionPaper"))
);
const GenerateQuestionPaper = Loadable(
  lazy(() => import("views/pages/entranceexam/GenerateQuestionPaper"))
);
const CreateLecture = Loadable(
  lazy(() => import("views/pages/LectureSchedule/CreateLecture"))
);
const ShowLectures = Loadable(
  lazy(() => import("views/pages/LectureSchedule/ShowLectures"))
);
const Student = Loadable(
  lazy(() => import("views/pages/VerifyStudent/Student"))
);
const Profile = Loadable(
  lazy(() => import("views/pages/VerifyStudent/Profile"))
);
const ManageLeave = Loadable(
  lazy(() => import("views/pages/leaveManagement/ManageLeave/manageLeave"))
);
const LeaveReport = Loadable(
  lazy(() => import("views/pages/leaveManagement/LeaveReport/leaveReport"))
);
const AddBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/AddBook"))
);
const LibraryRules = Loadable(
  lazy(() => import("views/pages/libraryManagement/LibraryRules"))
);
const AllBooks = Loadable(
  lazy(() => import("views/pages/libraryManagement/AllBooks"))
);
const DisposeBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/DisposeBook"))
);
const IssueBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/IssueBook"))
);
const RenewBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/RenewBook"))
);
const ReceiveBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/ReceiveBook"))
);
const UnVerifiedStudents = Loadable(
  lazy(() => import("views/pages/VerifyStudent/UnVerifiedStudent"))
);
const VerifiedStudent = Loadable(
  lazy(() => import("views/pages/VerifyStudent/VerifiedStudent"))
);
const CancelWaiting = Loadable(
  lazy(() => import("views/pages/libraryManagement/CancelWaiting"))
);
const SubmitAttendance = Loadable(
  lazy(() => import("views/pages/attendance/submitAttendence"))
);
const LectureAttendance = Loadable(
  lazy(() => import("views/pages/LectureSchedule/LectureAttendance"))
);
const AttendanceReport = Loadable(
  lazy(() => import("views/pages/attendance/AttendenceReport/AttendenceReport"))
);
const EntranceExamResult = Loadable(
  lazy(() => import("views/pages/entranceexam/ViewResult"))
);
const RegularExamResult = Loadable(
  lazy(() => import("views/pages/regularexam/ViewResult"))
);
const StudentProfile = Loadable(
  lazy(() => import("views/pages/studentProfile/Profile"))
);
const CreateDisciplinaryAction = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/CreateDisciplinaryAction"))
);
const AllDisciplinaryActions = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/AllDisciplinaryActions"))
);
const DisciplinaryReport = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/DisciplinaryReport"))
);

const CancelAdmissionRequest = Loadable(
  lazy(() => import("views/pages/AdminCancelAdmission/CreateCancelAdmission"))
);
const AllCancelAdmissionRequest = Loadable(
  lazy(() =>
    import("views/pages/AdminCancelAdmission/AllCancelAdmissionRequest")
  )
);
const SearchStudentFile = Loadable(
  lazy(() => import("views/pages/StudentFile/searchStudentFile"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "studentProfile/:studentIdParams",
      element: <StudentProfile />,
    },
    {
      path: "entranceExam",
      children: [
        {
          path: "createExam",
          element: <CreateExam />,
        },
        {
          path: "updateExam/:examIdParams",
          element: <CreateExam />,
        },
        {
          path: "allExams",
          element: <AllExams />,
        },
        {
          path: "setQuestions",
          element: <SetQuestionsForExam />,
        },
        {
          path: "setQuestions/:examIdParams/:examNameParams",
          element: <SetQuestionsForExam />,
        },
        {
          path: "showQuestions/:examIdParams/:examNameParams",
          element: <ShowQuestions />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaper />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaper />,
        },
        {
          path: "uploadResult",
          element: <UploadResult />,
        },
        {
          path: "uploadResult/:examIdParams",
          element: <UploadResult />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <EntranceExamResult />,
        },
        {
          path: "EditQuestion/:examIdParams/:questionIdParams",
          element: <UpdateQuestion />,
        },
      ],
    },
    {
      path: "cancelAdmission",
      children: [
        {
          path: "cancelAdmissionRequest",
          element: <CancelAdmissionRequest />,
        },
        {
          path: "allCancelAdmissionRequest",
          element: <AllCancelAdmissionRequest />,
        },
      ],
    },
    {
      path: "regularExam",
      children: [
        {
          path: "allocateExam",
          element: <AllocateExam />,
        },

        {
          path: "createExam",
          element: <CreateRegularExam />,
        },
        {
          path: "updateExam/:examIdParams",
          element: <CreateRegularExam />,
        },
        {
          path: "allExams",
          element: <AllRegularExams />,
        },
        {
          path: "generateResult",
          element: <UploadRegularResult />,
        },
        {
          path: "setQuestions",
          element: <SetQuestionsForRegularExam />,
        },
        {
          path: "setQuestions/:examIdParams/:examNameParams",
          element: <SetQuestionsForRegularExam />,
        },
        {
          path: "showQuestions/:examIdParams/:examNameParams/:batchIdParams",
          element: <ShowQuestionsForRegular />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaperRegular />,
        },
        {
          path: "uploadResult",
          element: <UploadRegularResult />,
        },
        {
          path: "uploadResult/:examIdParams",
          element: <UploadRegularResult />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <RegularExamResult />,
        },
        {
          path: "EditQuestion/:examIdParams/:batchIdParams/:questionIdParams",
          element: <UpdateRegQuestion />,
        },
      ],
    },
    {
      path: "disciplinaryAction",
      children: [
        {
          path: "createDisciplinaryAction",
          element: <CreateDisciplinaryAction />,
        },
        {
          path: "allDisciplinaryAction",
          element: <AllDisciplinaryActions />,
        },
        {
          path: "disciplinaryReport",
          element: <DisciplinaryReport />,
        },
      ],
    },
    {
      path: "verifyStudent",
      children: [
        {
          path: "unverifiedStudents",
          element: <UnVerifiedStudents />,
        },
        {
          path: "verifiedStudents",
          element: <VerifiedStudent />,
        },
        {
          path: "profile/:studentIdParams",
          element: <Profile />,
        },
        {
          path: "unverifiedStudents/:batchCodeParams",
          element: <UnVerifiedStudents />,
        },
        {
          path: "verifiedStudents/:batchCodeParams",
          element: <VerifiedStudent />,
        },
        {
          path: "StudentProfile/:studentIdParams/:batchCodeParams",
          element: <Student />,
        },
      ],
    },
    {
      path: "batch",
      children: [
        {
          path: "updateDetails/:batchCodeParams",
          element: <CreateBatch />,
        },
        {
          path: "createBatch",
          element: <CreateBatch />,
        },
        {
          path: "allBatches",
          element: <AllBatches />,
        },

        {
          path: "generateMerit",
          element: <GenerateMerit />,
        },
        {
          path: "generateICard",
          element: <GenerateICard />,
        },
        {
          path: "generateMerit/:batchCodeParams",
          element: <GenerateMerit />,
        },
        {
          path: "ViewStudent/:batchCodeParams",
          element: <ViewStudent />,
        },
      ],
    },
    {
      path: "questionBank",
      children: [
        {
          path: "createQuestion",
          element: <CreateQuestion />,
        },
        {
          path: "allQuestions",
          element: <AllQuestions />,
        },
        {
          path: "editQuestion/:questionIdParams",
          element: <EditQuestion />,
        },
      ],
    },
    {
      path: "grievanceManagement",
      children: [
        {
          path: "allGrievanceRequest",
          element: <AllGrievanceRequest />,
        },
      ],
    },
    // {
    //     path: 'lectureArrangement',
    //     children: [
    //         {
    //             path: 'scheduleLecture',
    //             element: <CreateLecture />
    //         },
    //         {
    //             path: 'allLectures',
    //             element: <ShowLectures />
    //         },
    //         {
    //             path: 'lectureAttendance/:lectureId',
    //             element: <LectureAttendance />
    //         },
    //         {
    //             path: 'updateLecture/:lectureId',
    //             element: <CreateLecture />
    //         }
    //     ]
    // },

    {
      path: "readingReport",
      children: [
        {
          path: "upadateReadingStatus",
          element: <UpdateReadingReportStatus />,
        },
      ],
    },
    {
      path: "leaveManagement",
      children: [
        {
          path: "manageLeave",
          element: <ManageLeave />,
        },
        {
          path: "leaveReport",
          element: <LeaveReport />,
        },
      ],
    },
    {
      path: "staffLeaveManagement",
      children: [
        {
          path: "manageLeaveRequest",
          element: <ManageStaffLeave />,
        },
      ],
    },
    {
      path: "studentFile",
      children: [
        {
          path: "searchStudentFile",
          element: <SearchStudentFile />,
        },
      ],
    },
    // {
    //     path: 'libraryManagement',
    //     children: [
    //         {
    //             path: 'addBook',
    //             element: <AddBook />
    //         },
    //         {
    //             path: 'libraryRules',
    //             element: <LibraryRules />
    //         },
    //         {
    //             path: 'allBooks',
    //             element: <AllBooks />
    //         },
    //         {
    //             path: 'disposeBook',
    //             element: <DisposeBook />
    //         },
    //         {
    //             path: 'issueBook',
    //             element: <IssueBook />
    //         },
    //         {
    //             path: 'renewBook',
    //             element: <RenewBook />
    //         },
    //         {
    //             path: 'receiveBook',
    //             element: <ReceiveBook />
    //         },
    //         {
    //             path: 'cancelWaiting',
    //             element: <CancelWaiting />
    //         }
    //     ]
    // },
    // {
    //     path: 'Attendance',
    //     children: [
    //         {
    //             path: 'submitAttendance',
    //             element: <SubmitAttendance />
    //         },
    //         {
    //             path: 'attendanceReport',
    //             element: <AttendanceReport />
    //         }
    //     ]
    // }
  ],
};

export default MainRoutes;
