// assets
import { IconDashboard } from "@tabler/icons";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ArticleIcon from "@mui/icons-material/Article";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  // title: 'Dashboard',
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: DashboardRoundedIcon,
      breadcrumbs: false,
    },
    {
      id: "Batch",
      title: "Batches",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Create Batch",
          title: "Create Batch",
          type: "item",
          url: "/dashboard/batch/createBatch",
          breadcrumbs: false,
        },
        {
          id: "All Batches",
          title: "All Batches",
          type: "item",
          url: "/dashboard/batch/allBatches",
          breadcrumbs: false,
        },
        {
          id: "Generate Merit",
          title: "Generate Merit",
          type: "item",
          url: "/dashboard/batch/generateMerit",
          breadcrumbs: false,
        },
        {
          id: "Generate ICard",
          title: "Generate ICard",
          type: "item",
          url: "/dashboard/batch/generateICard",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "entranceExam",
      title: "Entrance Exam",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "createExam",
          title: "Create Exam",
          type: "item",
          url: "/dashboard/entranceExam/createExam",
          breadcrumbs: false,
        },
        {
          id: "AllEntranceExams",
          title: "All Entrance Exams",
          type: "item",
          url: "/dashboard/entranceExam/allExams",
          breadcrumbs: false,
        },

        {
          id: "GenerateQuestionPaper",
          title: "Generate Question Paper",
          type: "item",
          url: "/dashboard/entranceExam/generateQuestionPaper",
          breadcrumbs: false,
        },
        {
          id: "Upload Result",
          title: "Upload Result",
          type: "item",
          url: "/dashboard/entranceExam/uploadResult",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "regularExam",
      title: "Regular Exam",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        /*    {
                    id: 'allocateExam',
                    title: 'Allocate Exam',
                    type: 'item',
                    url: '/dashboard/regularExam/allocateExam',
                    breadcrumbs: false
                }, */
        {
          id: "createExam",
          title: "Create Exam",
          type: "item",
          url: "/dashboard/regularExam/createExam",
          breadcrumbs: false,
        },
        {
          id: "All Regular Exams",
          title: "All Regular Exams",
          type: "item",
          url: "/dashboard/regularExam/allExams",
          breadcrumbs: false,
        },
        {
          id: "Upload Result",
          title: "Upload Result",
          type: "item",
          url: "/dashboard/regularExam/generateResult",
          breadcrumbs: false,
        },
        {
          id: "GenerateQuestionPaper",
          title: "Generate Question Paper",
          type: "item",
          url: "/dashboard/regularExam/generateQuestionPaper",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "VerifyStudent",
      title: "Verify Student",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Unverified Students",
          title: "Unverified Students",
          type: "item",
          url: "/dashboard/verifyStudent/unverifiedStudents",
          breadcrumbs: false,
        },
        {
          id: "Verified Students",
          title: "Verified Students",
          type: "item",
          url: "/dashboard/verifyStudent/verifiedStudents",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "Questions Bank",
      title: "Question Bank",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Create New Question",
          title: "Create New Question",
          type: "item",
          url: "/dashboard/questionBank/createQuestion",
          breadcrumbs: false,
        },
        {
          id: "AllQuestions",
          title: "All Questions",
          type: "item",
          url: "/dashboard/questionBank/allQuestions",
          breadcrumbs: false,
        },
      ],
    },
    // {
    //     id: 'Lecture Arrangement',
    //     title: 'Lecture Arrangement',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     breadcrumbs: true,
    //     children: [
    //         {
    //             id: 'Schedule Lecture',
    //             title: 'Schedule Lecture',
    //             type: 'item',
    //             url: '/dashboard/LectureArrangement/scheduleLecture',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'Show All Lectures',
    //             title: 'All Scheduled Lectures',
    //             type: 'item',
    //             url: '/dashboard/LectureArrangement/allLectures',
    //             breadcrumbs: true
    //         }
    //     ]
    // },
    {
      id: "Leave Management",
      title: "Leave Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "Manage Leave",
          title: "Manage Leave",
          type: "item",
          url: "/dashboard/leaveManagement/manageLeave",
          breadcrumbs: true,
        },
        {
          id: "Leave Report",
          title: "Leave Report",
          type: "item",
          url: "/dashboard/leaveManagement/leaveReport",
          breadcrumbs: true,
        },
      ],
    },

    //staff leave management
    {
      id: "Staff Leave Management",
      title: "Staff Leave Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "Raise Leave",
          title: "Raise Leave",
          type: "item",
          url: "/dashboard/staffLeaveManagement/raiseLeave",
          breadcrumbs: true,
        },
        {
          id: "My Leaves",
          title: "My Leaves",
          type: "item",
          url: "/dashboard/staffLeaveManagement/myLeaves",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "grievanceManagement",
      title: "Grievance Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "allGrievanceRequest",
          title: "All Grievance Request",
          type: "item",
          url: "/dashboard/grievanceManagement/allGrievanceRequest",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "disciplinaryAction",
      title: "Disciplinary Action",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "disciplinaryAction",
          title: "Create Disciplinary Action",
          type: "item",
          url: "/dashboard/disciplinaryAction/createDisciplinaryAction",
          breadcrumbs: true,
        },
        {
          id: "allDisciplinaryAction",
          title: "All Disciplinary Action",
          type: "item",
          url: "/dashboard/disciplinaryAction/allDisciplinaryAction",
          breadcrumbs: true,
        },
        {
          id: "disciplinaryReport",
          title: "Disciplinary Report",
          type: "item",
          url: "/dashboard/disciplinaryAction/disciplinaryReport",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "readingReport",
      title: "Student Reading Updates",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "upadateReadingStatus",
          title: "Update Reading Report",
          type: "item",
          url: "/dashboard/readingReport/upadateReadingStatus",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "studentFile",
      title: "Student File",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "searchStudentFile",
          title: "Search Student File",
          type: "item",
          url: "/dashboard/studentFile/searchStudentFile",
          breadcrumbs: true,
        },
      ],
    },
    // {
    //     id: 'Library Management',
    //     title: 'Liibrary Management',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     breadcrumbs: true,
    //     children: [
    //         {
    //             id: 'AddBook',
    //             title: 'Add Book',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/addBook',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'AllBooks',
    //             title: 'All Books',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/allBooks',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'DisposeBook',
    //             title: 'Dispose Book',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/disposeBook',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'issueBook',
    //             title: 'Issue Book',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/issueBook',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'renewBook',
    //             title: 'Renew Book',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/renewBook',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'receiveBook',
    //             title: 'Receive Book',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/receiveBook',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'cancelBookRequest',
    //             title: 'Cancel Book Request',
    //             type: 'item',
    //             url: '/dashboard/libraryManagement/cancelWaiting',
    //             breadcrumbs: true
    //         }
    //     ]
    // },
    // {
    //     id: 'Attendance',
    //     title: 'Attendance',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     breadcrumbs: true,
    //     children: [
    //         {
    //             id: 'SubmitAttendance',
    //             title: 'Submit Attendance',
    //             type: 'item',
    //             url: '/dashboard/Attendance/submitAttendance',
    //             breadcrumbs: true
    //         },
    //         {
    //             id: 'AttendanceReport',
    //             title: 'Attendance Report',
    //             type: 'item',
    //             url: '/dashboard/Attendance/attendanceReport',
    //             breadcrumbs: true
    //         }
    //     ]
    // }
  ],
};

export default dashboard;
